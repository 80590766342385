body {
  margin: 0;
  font-family: 'Noto Sans JP', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item {
  zoom: 0.3;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 80px;
  overflow: hidden;
  pointer-events: none;
}

.item100 {
  position: absolute;
  left: -0px;
  top: -0px;
}
.item101 {
  position: absolute;
  left: -150px;
  top: -0px;
}
.item102 {
  position: absolute;
  left: -300px;
  top: -0px;
}
.item104 {
  position: absolute;
  left: -450px;
  top: -0px;
}
.item110 {
  position: absolute;
  left: -600px;
  top: -0px;
}
.item112 {
  position: absolute;
  left: 0px;
  top: -80px;
}
.item115 {
  position: absolute;
  left: -150px;
  top: -80px;
}
.item200 {
  position: absolute;
  left: -300px;
  top: -80px;
}
.item201 {
  position: absolute;
  left: -450px;
  top: -80px;
}
.item202 {
  position: absolute;
  left: -600px;
  top: -80px;
}
.item204 {
  position: absolute;
  left: 0px;
  top: -160px;
}
.item210 {
  position: absolute;
  left: -150px;
  top: -160px;
}
.item212 {
  position: absolute;
  left: -300px;
  top: -160px;
}
.item215 {
  position: absolute;
  left: -450px;
  top: -160px;
}
.item300 {
  position: absolute;
  left: -600px;
  top: -160px;
}
.item301 {
  position: absolute;
  left: 0px;
  top: -240px;
}
.item302 {
  position: absolute;
  left: -150px;
  top: -240px;
}
.item303 {
  position: absolute;
  left: -300px;
  top: -240px;
}
.item311 {
  position: absolute;
  left: -450px;
  top: -240px;
}
.item313 {
  position: absolute;
  left: -600px;
  top: -240px;
}
.item314 {
  position: absolute;
  left: 0px;
  top: -320px;
}
.item400 {
  position: absolute;
  left: -150px;
  top: -320px;
}
.item401 {
  position: absolute;
  left: -300px;
  top: -320px;
}
.item402 {
  position: absolute;
  left: -450px;
  top: -320px;
}
.item403 {
  position: absolute;
  left: -600px;
  top: -320px;
}
.item411 {
  position: absolute;
  left: 0px;
  top: -400px;
}
.item413 {
  position: absolute;
  left: -150px;
  top: -400px;
}
.item414 {
  position: absolute;
  left: -300px;
  top: -400px;
}
.item000 {
  position: absolute;
  left: -450px;
  top: -400px;
}
